<template>
  <div id="project-afkir-dini-target-detail">
    
    <div class="card card-custom gutter-b">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <router-link
            to="/project/afkir-dini/target"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
          <li class="nav-item">
            <a
              class="nav-link"
              role="tab"
              :href="href" 
              @click="navigate"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <span class="svg-icon svg-icon-md svg-icon-success m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Navigation/Left-2.svg"/>
                <!--end::Svg Icon-->
              </span>
              Kembali
            </a>
          </li>
          </router-link>
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              role="tab"
            >
              View Data
            </a>
          </li>
        </ul>
      </div>

      <!--begin::View Data Farm-->
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div class="mx-5" v-if="dataTarget != null">
                <!--begin::Top-->
                <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-65 symbol-light d-sm-inline d-none mr-5">
                        <span class="symbol-label">
                          <span class="svg-icon svg-icon-sm-3x svg-icon-primary">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/icons/General/Clipboard.svg"/>
                            <!--end::Svg Icon-->
                          </span>
                        </span>
                    </div>
                    <!--end::Symbol-->

                    <!--begin::Info-->
                    <div class="d-flex flex-column flex-grow-1">
                        <a class="text-dark-75 text-hover-primary mb-1 font-size-h5 font-weight-bolder">
                          No.SE {{ dataTarget.no_se }}
                        </a>
                        <div class="d-sm-flex d-block">
                            <div class="d-flex align-items-center mb-1 mr-sm-5">
                              <span class="svg-icon svg-icon-lg svg-icon-primary mr-1">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Home/Clock.svg"/>
                                <!--end::Svg Icon-->
                              </span>                       
                              <span class="text-muted font-weight-bold">{{ dataTarget.tgl_se | dateIndonesia }}</span>
                            </div>
                        </div>
                        <div class="d-flex align-items-center mb-1 mr-sm-5 cursor-pointer" @click="downloadPdf(dataTarget.file)">
                          <span class="svg-icon svg-icon-lg svg-icon-primary mr-1">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/icons/Files/DownloadedFile.svg"/>
                            <!--end::Svg Icon-->
                          </span>                       
                          <span class="text-primary font-weight-bolder">Download File</span>
                        </div>
                    </div>
                    <!--end::Info-->

                </div>
                <!--end::Top-->

                <!--begin::Bottom-->
                <div class="pt-3">

                    <!--begin::Separator-->
                    <div class="separator separator-solid mt-6 mb-4"></div>
                    <!--end::Separator-->

                    <!--begin::Description-->

                    <div class="border rounded p-3 mb-5">
                      <div class="d-flex align-items-center mx-3">
                        <div class="symbol symbol-35 symbol-light mr-4">
													<span class="symbol-label">
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <!--begin::Svg Icon-->
                              <inline-svg src="media/svg/icons/General/Attachment2.svg"/>
                              <!--end::Svg Icon-->
                            </span> 
													</span>
												</div>         
                        <span class="text-dark-75 font-size-h5 font-weight-bolder">
                            Target
                        </span>
                      </div>
                      
                      <!--begin::Separator-->
                      <div class="separator separator-solid mt-3 mb-4"></div>
                      <!--end::Separator-->

                      <div v-if="dataTarget.afkirdini.length > 0">
                        <div v-for="(rinc, index) in dataTarget.afkirdini" :key="index" class="border rounded p-3 mb-5">
                          <div class="d-flex align-items-center mx-3">
                            <div class="symbol symbol-35 symbol-light mr-4">
                              <span class="symbol-label">
                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg src="media/svg/icons/Home/Clock.svg"/>
                                  <!--end::Svg Icon-->
                                </span> 
                              </span>
                            </div>         
                            <span class="text-dark-75 font-size-lg font-weight-bolder">
                                <span class="mr-2">{{ rinc.tgl_awal | dateIndonesia }} ~ {{ rinc.tgl_akhir | dateIndonesia }}</span>
                            </span>
                          </div>
                          
                          <!--begin::Separator-->
                          <div class="separator separator-solid mt-3 mb-4"></div>
                          <!--end::Separator-->

                          <!--begin::Top-->
                          <div class="d-flex align-items-center">
                              <!--begin::Info-->
                              <div class="d-flex flex-column flex-grow-1">
                                <div class="d-sm-flex d-block">
                                    <div class="d-flex align-items-center mb-1 mr-sm-5">
                                      <span class="svg-icon svg-icon-lg svg-icon-primary mr-1">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Layout/Layout-arrange.svg"/>
                                        <!--end::Svg Icon-->
                                      </span>                       
                                      <span class="text-muted font-weight-bold" v-for="(child, idx) in rinc.child" :key="idx">
                                        {{ (idx > 0 ? ', ':'') }} {{ child.sex }} <span class="text-primary font-weight-bolder">{{ child.jumlah | nominal }}</span> ekor
                                      </span>
                                    </div>
                                </div>
                              </div>
                              <!--end::Info-->

                          </div>
                          <!--end::Top-->
                        </div>
                      </div>
                      <div v-else class="border rounded p-3 mb-5 text-center">
                        <span class="text-dark-75 font-size-lg font-weight-bolder">Tidak ada data Target</span>
                      </div>   
                    </div>          

                    <!--end::Description-->
                </div>
                <!--end::Bottom-->
              </div>

              <div v-else>
                <FacebookLoader></FacebookLoader>
              </div>

            </b-tab>
          </b-tabs>
        </div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FacebookLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AfkirDiniService from "@/core/services/api/data/afkirdini.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "project-afkir-dini-target-detail",
  props: {
    idS: {
      required: true
    }
  },
  components: {
    FacebookLoader
  },
  data() {
    return {
      tabIndex: 0,
      dataTarget: null
    }
  },
  computed:{
    ...mapGetters(["currentUser"]),
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");

      // keep active tab
      localStorage.setItem("builderTab", this.tabIndex);
    },

    getData () {
      AfkirDiniService.getTargetDetail({ _id: this.idS })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.dataTarget = res.data.data
        })
        .catch((err) => ErrorService.status(err))
    },
    downloadPdf(file) {
      if (file) {
        window.open(`${process.env.VUE_APP_API_URL}/dokumen/surat-edaran/${file}`, "_blank"); 
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Histori Afkir Dini" }]);
    if (!this.currentUser.menu.afkir_dini) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getData()
  },
};
</script>
<style lang="scss">
  #project-afkir-dini-target-detail {
    .iframe-content {
      height: 400px;
      overflow: auto;
      
      #gmaps {
        height: 100%;
      }
    }
  }

</style>
